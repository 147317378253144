<template>
  <main class="fullheight">
    <my-header></my-header>
    <salva-sessione v-if="confirmArea"></salva-sessione>
    <section v-if="insertArea" id="input-area" v-bind:class="{'open':insertArea}" tabindex="-1" :autofocus="insertArea">
      <div class="container">
        <div class="row">
          <div class="col-12 info">
            <p>Sequenza di inserimento:</p>
            <ol>
              <li><b>Identificativo magazzino</b></li>
              <li><b>Barcode prodotto (+ lotto)</b></li>
              <li><b>Barcode ubicazione</b></li>
            </ol>
          </div>
        </div>
        <form novalidate>
          <div class="row mt-4">
            <div class="col-12">
              <div class="form-group-select">
                <label class="">Magazzino</label>
                <!-- <input type="text" id="codice-magazzino" ref="warehouse" v-model="warehouse_code" @focus="scanState = 'warehouse'" :required="scanData.warehouse.required" @keypress.enter="checkEnterKey"> -->
                <select id="warehouse" class="w-100" ref="warehouse" :required="scanData.warehouse.required" @change="resetForm">
                  <option v-if="!scanData.location.show" value=""></option>
                  <option v-bind:key="index" v-for="(whitem, index) in companyWarehouses" :value="whitem.id" :selected="whitem.id===scanData.warehouse.value">{{whitem.nome}}</option>
                </select>
              </div>
              <div class="form-group">
                <input type="text" id="article" ref="article" @focus="validateInputFocus" :required="scanData.article.required" @keypress.enter="checkEnterKey" @click="noClickPlease">
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="required">Barcode prodotto</label>
              </div>
              <div v-if="scanData.lot.show" class="form-group">
                <input type="text" id="lot" ref="lot" @focus="validateInputFocus" :required="scanData.lot.required" @click="noClickPlease" @keypress.enter="checkEnterKey">
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="required">Barcode Lotto</label>
              </div>
              <div v-if="scanData.location.show" class="form-group">
                <input type="text" id="location" ref="location" @focus="validateInputFocus" :required="scanData.location.required" @click="noClickPlease" @keypress.enter="checkEnterKey">
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="required">Barcode Ubicazione</label>
              </div>
              <div class="text-center mt-5" v-if="loading">
                <div class="logx-loading active">
                  <div class="blue-vbar vbar"></div>
                  <div class="red-vbar vbar"></div>
                  <div class="yellow-vbar vbar"></div>
                  <div class="green-vbar vbar"></div>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
    </section>

    <section v-if="rowsArea" id="righe-movimenti" v-bind:class="{'open':rowsArea}" tabindex="-1" :autofocus="rowsArea">
      <div class="list-group" tabindex="-1">
        <div v-for="(item, index) in this.rows"
             :key="index" class="list-group-item"
             v-bind:class="{ 'active': index===selectedRow }"
             tabindex="-1"
             @click="scrollToRows(index)">
          <div class="list-group-item-action">
            <div class="info" v-bind:class="{'notes':item.noteoperatore}">RIGA <b>{{index+1}}</b></div>
            <div class="action-edit" v-if="index!==selectedRow"></div>
            <div class="action-delete" v-if="index!==selectedRow"></div>
            <div class="action-delete action-row" v-if="index===selectedRow"><span class="pointer" @click="deleteRow=true">ELIMINA RIGA <b>{{index+1}}</b><span class="user-key backspace"></span></span></div>
            <div class="action-edit action-row" v-if="index===selectedRow"><span class="pointer" @click="enterEditRow">MODIFICA RIGA <b>{{index+1}}</b><span class="user-key enter"></span></span></div>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-6">
                <span class="label">CODICE</span>
                <p>{{item.codice}}</p>
              </div>
              <div class="col-2">
                <span class="label">PZ / COLLO</span>
                <p class="light">{{item.pzcollo}}</p>
              </div>
              <div class="col-2">
                <span class="label">QNT. PZ</span>
                <p class="light">{{item.qta_totale}}</p>
              </div>
              <div class="col-2">
                <span class="label">QNT. COLLI</span>
                <p class="big">{{item.ncolli}}</p>
              </div>
              <div class="col-12">
                <span class="label">DESCRIZIONE</span>
                <p class="cond">{{item.descrizione}}</p>
              </div>
              <div class="col-4">
                <span class="label">LOTTO</span>
                <p>{{item.lotto}}</p>
              </div>
              <div class="col-4">
                <span class="label">UBICAZIONE</span>
                <p>{{item.ubicazione}}</p>
              </div>
              <div class="col-4">
                <span class="label">GIACENZA</span>
                <p class="light">{{item.giacenza}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="backdrop-modal open" v-show="(editRow||deleteRow||infoRow)&&!confirmArea"></div>
    <div class="dettagli-riga open" v-show="editRow && !confirmArea">
      <div class="dettagli-info edit">
        <span>MODIFICA</span> RIGA <b>{{selectedRow + 1}}</b>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="label">CODICE</span>
          <p>{{selectedRowObj.codice}}</p>
        </div>
        <div class="col-12">
          <span class="label">DESCRIZIONE</span>
          <p>{{selectedRowObj.descrizione}}</p>
        </div>
        <div class="col-5">
          <span class="label">LOTTO</span>
          <p>{{selectedRowObj.lotto}}</p>
        </div>
        <div class="col-3">
          <span class="label">MAGAZZINO</span>
          <p>{{selectedRowObj.magazzino}}</p>
        </div>
        <div class="col-4">
          <span class="label">UBICAZIONE</span>
          <p>{{selectedRowObj.ubicazione}}</p>
        </div>
        <div class="col-4">
          <span class="label">GIACENZA</span>
          <p class="light">{{selectedRowObj.giacenza}}</p>
        </div>
        <div class="col-4">
          <span class="label">DISPONIBILITÀ LORDA</span>
          <p class="light">{{selectedRowObj.dispo_lorda}}</p>
        </div>
        <div class="col-4">
          <span class="label">DISPONIBILITÀ NETTA</span>
          <p class="light">{{selectedRowObj.dispo_netta}}</p>
        </div>
        <div class="col-4">
          <span class="label">PZ / COLLO</span>
          <p class="light">{{selectedRowObj.pzcollo}}</p>
        </div>
        <div class="col-4">
          <span class="label">QNT. PZ</span>
          <p class="light">{{selectedRowObj.qta_totale}}</p>
        </div>
        <div class="col-4">
          <span class="label">QNT. COLLI</span>
          <p class="big main-color">{{selectedRowObj.ncolli}}</p>
        </div>
        <div class="col-12">
          <span class="label">NOTE</span>
          <p class="light cond">{{selectedRowObj.note1_mexal}}</p>
        </div>
      </div>
      <div class="row mt-4 text-center">
        <div class="col-3">
          <div class="form-group text-left">
            <input type="text" ref="qnt_manuale" :value="selectedRowObj.ncolli" v-on:keydown.stop="validateManualQnt">
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="required">QNT. COLLI</label>
          </div>
        </div>
        <div class="col-9">
          <div class="form-group text-left">
            <input type="text" class="light cond" ref="note_operatore" :value="selectedRowObj.noteoperatore" v-on:keydown.stop="validateOperatorNote" :maxlength="maxLengthNote">
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="required">NOTA OPERATORE</label>
          </div>
        </div>
        <div class="col-12">
          <p class="light mb-2">Confermi modifiche?</p>
        </div>
        <div class="col-6">
          <button class="logx btn btn-annulla" @click="editRow=false">Annulla</button>
        </div>
        <div class="col-6">
          <button class="logx btn btn-conferma" @click="confirmEdit">Conferma</button>
        </div>
      </div>
    </div>

    <div class="dettagli-riga open" v-show="deleteRow && !confirmArea">
      <div class="dettagli-info delete">
        <span>ELIMINA</span> RIGA <b>{{selectedRow + 1}}</b>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="label">CODICE</span>
          <p>{{selectedRowObj.codice}}</p>
        </div>
        <div class="col-12">
          <span class="label">DESCRIZIONE</span>
          <p>{{selectedRowObj.descrizione}}</p>
        </div>
        <div class="col-5">
          <span class="label">LOTTO</span>
          <p>{{selectedRowObj.lotto}}</p>
        </div>
        <div class="col-3">
          <span class="label">MAGAZZINO</span>
          <p>{{selectedRowObj.magazzino}}</p>
        </div>
        <div class="col-4">
          <span class="label">UBICAZIONE</span>
          <p>{{selectedRowObj.ubicazione}}</p>
        </div>
        <div class="col-4">
          <span class="label">GIACENZA</span>
          <p class="light">{{selectedRowObj.giacenza}}</p>
        </div>
        <div class="col-4">
          <span class="label">DISPONIBILITÀ LORDA</span>
          <p class="light">{{selectedRowObj.dispo_lorda}}</p>
        </div>
        <div class="col-4">
          <span class="label">DISPONIBILITÀ NETTA</span>
          <p class="light">{{selectedRowObj.dispo_netta}}</p>
        </div>
        <div class="col-4">
          <span class="label">PZ / COLLO</span>
          <p class="light">{{selectedRowObj.pzcollo}}</p>
        </div>
        <div class="col-4">
          <span class="label">QNT. PZ</span>
          <p class="light">{{selectedRowObj.qta_totale}}</p>
        </div>
        <div class="col-4">
          <span class="label">QNT. COLLI</span>
          <p class="big main-color">{{selectedRowObj.ncolli}}</p>
        </div>
        <div class="col-12">
          <span class="label">NOTE</span>
          <p class="light cond">{{selectedRowObj.note1_mexal}}</p>
        </div>
        <div class="col-12">
          <span class="label">NOTE OPERATORE</span>
          <p>{{selectedRowObj.noteoperatore}}</p>
        </div>
      </div>
      <div class="row mt-4 text-center">
        <div class="col-12">
          <p class="light mb-2">Confermi eliminazione riga?</p>
        </div>
        <div class="col-6">
          <button class="logx btn btn-annulla" @click="deleteRow=false">Annulla</button>
        </div>
        <div class="col-6">
          <button class="logx btn btn-elimina" @click="confirmDelete">Conferma</button>
        </div>
      </div>
    </div>

    <div class="dettagli-riga open info" v-show="infoRow">
      <div class="row">
        <div class="col-12 mb-4">
          Riepilogo informazioni prodotto
        </div>
        <div class="col-6">
          <span class="label">CODICE PRODOTTO</span>
          <p>{{selectedRowObj.codice}}</p>
        </div>
        <div class="col-6">
          <span class="label">LOTTO</span>
          <p>{{selectedRowObj.lotto}}</p>
        </div>
        <div class="col-12">
          <span class="label">DESCRIZIONE PRODOTTO</span>
          <p>{{selectedRowObj.descrizione}}</p>
        </div>
        <div class="col-3">
          <span class="label">MAGAZZINO</span>
          <p>{{selectedRowObj.magazzino}}</p>
        </div>
        <div class="col-6">
          <span class="label">UBICAZIONE</span>
          <p>{{selectedRowObj.ubicazione}}</p>
        </div>
        <div class="col-3">
          <span class="label">GIACENZA</span>
          <p class="light">{{selectedRowObj.giacenza}}</p>
        </div>
        <div class="col-3">
          <span class="label">DISP. LORDA</span>
          <p class="light">{{selectedRowObj.dispo_lorda}}</p>
        </div>
        <div class="col-3">
          <span class="label">DISP. NETTA</span>
          <p class="light">{{selectedRowObj.dispo_netta}}</p>
        </div>
        <div class="col-3">
          <span class="label">PZ / COLLO</span>
          <p class="light">{{selectedRowObj.pzcollo}}</p>
        </div>
        <div class="col-3">
          <span class="label">QNT PZ</span>
          <p class="light">{{selectedRowObj.qta_totale}}</p>
        </div>
        <div class="col-12">
          <span class="label">NOTE MEXAL</span>
          <p class="light cond">{{selectedRowObj.note1_mexal}}</p>
        </div>
      </div>
      <div class="mt-3 row">
        <div class="col-12">
          <div class="form-group mb-1">
            <input type="text" ref="qnt_manuale" :value="selectedRowObj.ncolli" v-on:keydown.stop="validateManualQnt">
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="required">N. COLLI</label>
          </div>
          <div class="light cond alert-color fs-85 lh-1 mb-2">Inserire la quantità manualmente o ripetere la lettura del barcode per incrementare la quantità al valore richiesto.</div>
        </div>
      </div>
      <div class="mt-3 row">
        <div class="col-12 ">
          <div class="form-group">
            <input type="text" class="light cond" ref="note_operatore" :value="selectedRowObj.noteoperatore" v-on:keydown.stop="validateOperatorNote" :maxlength="maxLengthNote">
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="required">NOTA</label>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-12">
          <button class="logx btn btn-conferma" @click="confirmEdit">Conferma</button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
  //Importo Vue
  //import Vue from 'vue';

  //Importo l'HEADER
  import Header from '../components/Header';

  //Importo il modulo Store
  import store from "@/vuex/store";

  //Importo il componente salva sessione
  import salvaSessione from '../moduli/components/vendite/SalvaSessione';

  //Importo il logistix.config.js
  var config = require('../logistix.config');

  export default {
    name: "app",
    data() {
      return {
        scanState: 'article', // se mi arriva una scansione, dove la metto?
        scanData: {
          'warehouse': {
            'required': true,
            'show': true,
            'value': store.state.warehouses.length>0 ? store.state.warehouses[0].id : null,
            'barcode': '',
            'state': false
          },
          'article': {
            'required': true,
            'show': true,
            'value': null,
            'barcode': '',
            'state': false
          },
          'lot': {
            'required': true,
            'show': true,
            'value': null,
            'barcode': '',
            'state': false
          },
          'location': {
            'required': true,
            'show': true,
            'value': null,
            'barcode': '',
            'state': false
          },
        },

        rows: [],
        selectedRow: 0,
        editRow: false,
        deleteRow: false,
        infoRow: false,
        selectedRowObj: [],
        maxLengthNote: store.state.max_len_note,
        optionsLicense: store.state.options,
        companyWarehouses: store.state.warehouses,
        loading: false
      }
    },
    computed: {
      insertArea: function() {
        /// METTERE UN MUTATORE, PENA CASTRAZIONE FISICA
        return store.state.menu.insertArea;
      },
      confirmArea: function() {
        return store.state.menu.confirmArea;
      },
      rowsArea: function() {
        return store.state.menu.rowsArea;
      }
    },
    methods: {
      validateInputFocus: function () {
        console.log('scanState',this.scanState)
        switch (this.scanState) {
          case 'warehouse':
            /* essendo un select il controllo non lo possiamo fare
            if (this.scanData.warehouse.state) {
              this.$refs.article.focus()
              this.scanState = 'article'
            } else {
              this.$refs.warehouse.focus()
            }
            */
            break
          case 'article':
            if (this.scanData.article.state) {
              this.$refs.lot.focus()
              this.scanState = 'lot'
            } else {
              this.$refs.article.focus()
            }
            break
          case 'lot':
            if (this.scanData.lot.state) {
              this.$refs.location.focus()
              this.scanState = 'location'
            } else {
              this.$refs.lot.focus()
            }
            break
          case 'location':
            this.scanState = 'location'
        }
      },
      noClickPlease: function (evt) {
        evt.target.blur()
        this.validateInputFocus()
        return false
      },
      resetForm: function () {
        this.scanData.article.value = null;
        this.scanData.lot.value = null;
        this.scanData.location.value = null;
        this.$refs.article.value = '';
        this.$refs.lot.value = '';
        this.$refs.location.value = '';
        this.$refs.article.focus();
      },
      enterEditRow: function () {
        this.editRow = true;
        setTimeout(() => this.$refs.qnt_manuale.focus(), 300);
      },
      checkEnterKey: function () {
        switch (this.scanState) {
          case 'article':
            this.checkArticle(this.$refs.article.value);
            break;
          case 'lot':
            this.checkLot(this.$refs.lot.value);
            break;
          case 'location':
            this.checkLocation(this.$refs.location.value);
            break;
        }
      },
      checkWarehouse: function () {
        // l'input è stato sostituito dal select
      },

      //Funzione per controllare il barcode articolo inserito
      checkArticle: function (code) {
        this.loading = true

        //Chiamata alle api per il controllo del barcode prodotto, da sistemare con Marco
        /*fetch(config.apiUrl.proxy + 'license.php', { //Api giuste 'licenze', api test 'license.php'
          method: 'get',
          headers: {
            'X-API-KEY': config.idApp
          }
        })
          .then((response) => {
            return response.json()
          })
          .then((jsonData) => {
            console.log(jsonData);
        })*/

        if (code === 'articolo1') { //4002604374767
          this.$refs.article.value = '0001 - Prodotto';

          //Controlla se è stato appena letto o inserito il codice precedente
          if (this.scanData.article.value === code
              && (this.scanData.lot.value || !this.optionsLicense.lotti || !this.scanData.lot.required)
              && (this.scanData.location.value || !this.optionsLicense.ubicazione)) {
            // Aggiungi articolo nelle righe
          } else {
            // Controlla se nelle righe movimenti è già presente il codice inserito
            // in tal caso incrementa solo la quantità di n_colli
            Object.keys(this.rows).forEach(key => {
              if (this.rows[key].barcode_articolo === code
                  && this.rows[key].magazzino === this.scanData.warehouse.value
                  && this.rows[key].lotto === this.scanData.lot.value
                  && this.rows[key].ubicazione === this.scanData.location.value
              ) {
                this.rows[key].ncolli++;
                this.scanData.warehouse.value = this.rows[key].magazzino;
                this.scanData.lot.value = this.rows[key].lotto;
                this.scanData.location.value = this.rows[key].ubicazione;
              }
            })
            if(!this.scanData.lot.value && this.optionsLicense.lotti && this.scanData.lot.required) {
              //this.scanState = 'lot';
              this.scanData.article.state = true;
              this.validateInputFocus();
            }
            else if(!this.scanData.location.value && this.optionsLicense.ubicazione && this.scanData.location.required) {
              //this.scanState = 'location';
              this.scanData.lot.state = true;
              this.validateInputFocus();
            }
          }

          // il code qui deve essere il codice prodotto da gestionale restituito dalle API!
          this.scanData.article.value = code
        } else {
          //var refsthis = this.$refs;
          // Can accept an Object of options
          this.$toast.open({
            title: '',
            message: 'Barcode prodotto non corrispondente!',
            type: 'error',
            timeout: 100
          });
          this.validateInputFocus();
          console.warn('Barcode prodotto non corrispondente')
        }
        setTimeout(()=>this.loading=false,1000)
      },

      //Funzione per controllare il barcode lotto inserito
      checkLot: function(code) {
        this.loading = true

        //Chiamata alle api per il controllo del barcode lotto, da sistemare con Marco
        /*fetch(config.apiUrl.proxy + 'license.php', { //Api giuste 'licenze', api test 'license.php'
          method: 'get',
          headers: {
            'X-API-KEY': config.idApp
          }
        })
          .then((response) => {
            return response.json()
          })
          .then((jsonData) => {
            console.log(jsonData);
        })*/

        if(code=='lotto1') {
          //this.scanState = 'location';
          this.scanData.lot.state = true;
          this.validateInputFocus();
        } else {
          //var refsthis = this.$refs;
          // Can accept an Object of options
          this.$toast.open({
            title: '',
            message: 'Barcode lotto non corrispondente!',
            type: 'error',
            timeout: 100
          });
          this.validateInputFocus();
          console.warn('Barcode lotto non corrispondente')
        }
        setTimeout(()=>this.loading=false,1000)
      },


      //Funzione per controllare il barcode lotto inserito
      checkLocation: function(code) {
        this.loading = true

        //Chiamata alle api per il controllo del barcode lotto, da sistemare con Marco
        /*fetch(config.apiUrl.proxy + 'license.php', { //Api giuste 'licenze', api test 'license.php'
          method: 'get',
          headers: {
            'X-API-KEY': config.idApp
          }
        })
          .then((response) => {
            return response.json()
          })
          .then((jsonData) => {
            console.log(jsonData);
        })*/

        if(code=='ubic1') {
          this.infoRow = true;
        } else {
          //var refsthis = this.$refs;
          // Can accept an Object of options
          this.$toast.open({
            title: '',
            message: 'Barcode ubicazione non corrispondente!',
            type: 'error',
            timeout: 100
          });
          this.validateInputFocus();
          console.warn('Barcode ubicazione non corrispondente')
        }

        setTimeout(()=>this.loading=false,1000)
      },

      populateField: function (code) {

        if (this.scanState == 'warehouse') {
          this.scanData.warehouse.value = code
          this.$refs.article.focus();
          return;
        }

        if (this.scanState == 'article') {
          this.$refs.article.value = code;
          this.checkArticle(code);
          this.$refs.lot.focus();
          return;
        }

        if (this.scanState == 'lot') {
          this.scanData.lot.value = code
          this.$refs.location.focus();
          return;
        }

        if (this.scanState == 'location') {
          this.scanData.location.value = code
          return;
        }
      },
      setFirstTimeFocus: function () {
        setTimeout(() => this.$refs.article.focus(), 100);

        return true;
      },
      validateManualQnt: function (evt) {
        switch (evt.key) {
          case 'Enter':
            this.$refs.note_operatore.focus();
            break;
          case ' ':
            evt.preventDefault();
            break;
          default :
            if (isNaN(evt.key) && evt.key !== 'Backspace') {
              evt.preventDefault();
              return false;
            }
            break;
        }
      },
      validateOperatorNote: function (evt) {
        switch (evt.key) {
          case 'Enter':
            this.confirmEdit()
            break;
          default :
            break
        }
      },
      checkRows: function () {
        fetch(config.apiUrl.proxy + 'prova_righe.php', {
          method: 'get',
          headers: {
            'X-API-KEY': config.idApp
          }
        })
            .then((response) => {
              return response.json()
            })
            .then((jsonData) => {
              if (jsonData.length > 0) {
                //store.state.menu.insertArea = false;
                this.rows = jsonData;
                this.selectedRowObj = this.rows[0];
              } else {
                store.state.menu.insertArea = true;
              }
            })
      },
      checkKeyEditArea: function (evt) {
        if (store.state.menu.keydownPageEnabled && this.rowsArea) {
          console.log('Keydown Vendite.vue', evt.key, 'checkKey()')
          if (!this.editRow && !this.deleteRow) {
            if (evt.key === '8' || evt.key === 'ArrowUp') {
              evt.preventDefault();
              this.selectRowsUp(evt.key);
            } else if (evt.key === '2' || evt.key === 'ArrowDown') {
              evt.preventDefault();
              this.selectRowsDown(evt.key);
            }
            return
          }
          if (evt.key === 'Enter') {
            if (this.editRow) {
              this.confirmEdit();
            } else if (this.deleteRow) {
              this.confirmDelete();
            } else {
              this.editRow = true;
            }
          } else if (evt.key === 'Backspace') {
            if (this.deleteRow) {
              this.deleteRow = false;
            } else if (this.editRow) {
              this.editRow = false;
            } else {
              this.deleteRow = true;
            }
          }
        } else if (store.state.menu.keydownPageEnabled && this.insertArea) {
          if (evt.key === 'Tab') {
            evt.preventDefault()
            return false
          }
        }
      },
      selectRowsUp: function (key) {
        if (this.selectedRow > 0) {
          this.selectedRow--;
          this.selectedRowObj = this.rows[this.selectedRow];
        }
        if (key === '8') {
          document.querySelectorAll('.list-group-item')[this.selectedRow].scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
          });
        }
      },
      selectRowsDown: function (key) {
        if (this.selectedRow < this.rows.length - 1) {
          this.selectedRow++;
          this.selectedRowObj = this.rows[this.selectedRow];
        }
        if (key === '2') {
          document.querySelectorAll('.list-group-item')[this.selectedRow].scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
          });
        }
      },
      scrollToRows: function (index) {
        this.selectedRow = index;
        this.selectedRowObj = this.rows[this.selectedRow];
      },
      confirmEdit: function () {
        if (this.$refs.qnt_manuale.value === '0' || this.$refs.qnt_manuale.value === '') {
          /*const messaggio = 'Il numero richiesto deve essere maggiore di 0',
              tipo = 'alert',
              title = 'Attenzione!',
              livello = 'Errore';
          store.state.logx_dialog(tipo, livello, title, messaggio);
           */
          this.$toast.open({
            title: '',
            message: 'Inserire un numero maggiore di 0!',
            type: 'error',
            timeout: 100
          });
          this.$refs.qnt_manuale.focus();
        } else {
          this.editRow = false;
          this.rows[this.selectedRow].ncolli = this.$refs.qnt_manuale.value;
          this.rows[this.selectedRow].noteoperatore = this.stringTrim(this.$refs.note_operatore.value);
        }
      },
      confirmDelete: function () {
        this.deleteRow = false;
        console.log('CONFERMATO DELETE');
        delete this.rows[this.selectedRow];
        if (this.selectedRow > 0) {
          this.selectedRow--;
        }
        var i = 0;
        var newarray = [];
        Object.keys(this.rows).forEach(key => {
          newarray[i] = this.rows[key];
          i++;
        })
        this.rows = newarray;
        newarray = null;
        if (this.rows.length === 0) {
          store.state.menu.insertArea = true;
        }
      },
      stringTrim: function (str) {
        if (typeof str === 'string') {
          return str.trim();
        }
        return str;
      }
    },
    components:{
      'my-header':Header,
      'salva-sessione':salvaSessione
    },
    created:function() {
      localStorage.idmodule = '4';

      //Effettuo il controllo se esistono righe già inserite e in sessione
      this.checkRows();
    },
    mounted: function() {
      document.addEventListener("keydown", this.checkKeyEditArea);

      this.unsubscribe = store.subscribe((mutation, state) => {

        if (mutation.type == 'showInsertArea') {
          this.setFirstTimeFocus();
          return;
        }

        if(state.barcodeReaderData.code) {
          this.populateField(state.barcodeReaderData.code);
        }
      });
    },
    beforeDestroy: function() {
      this.unsubscribe();
      document.removeEventListener("keydown", this.checkKeyEditArea);
    }
  }
</script>
