<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="form-group-select">
          <label class="">Seleziona il cliente</label>
          <v-select label="nome"
                    :filterable="false"
                    :options="options"
                    @search="onSearchClients"
                    v-model="searchClient"
                    @input="updateSelectedClient">
            <template slot="no-options">
              Cerca il cliente...
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <b>{{ option.codice }}</b><br />
                {{ option.nome }}<br />
                {{ option.citta }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                <b>{{ option.codice }}</b><br />
                {{ option.nome }}<br />
                {{ option.citta }}
              </div>
            </template>
          </v-select>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group-select">
          <label>Seleziona il tipo documento</label>
          <v-select-2 :disabled="!selectedClient"
                      :value="selectedDocument"
                      label="mdsig"
                      :filterable="false"
                      :options="options"
                      @search="onSearchDocuments"
                      v-model="selectedDocument"
                      id="documento"
                      ref="documento"
                      @input="updateSelectedDocument">
            <template slot="no-options">
              Cerca il documento...
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.mdsig }}<br />
                {{ option.mddes }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.mdsig }}
              </div>
            </template>
          </v-select-2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button class="logx btn btn-block btn-large btn-primary" v-if="confirmButton" type="button" @click="sendData">CONFERMA</button>
      </div>
    </div>
    <div class="text-center mt-5" v-if="loading">
      <div class="logx-loading active">
        <div class="blue-vbar vbar"></div>
        <div class="red-vbar vbar"></div>
        <div class="yellow-vbar vbar"></div>
        <div class="green-vbar vbar"></div>
      </div>
    </div>
  </div>
</template>

<script>
//Importo il modulo Store
//import store from "@/vuex/store";

//import Vue from 'vue';
import VueSelect from 'vue-select';

import '../../../assets/css/logistix-select.css';
//import 'vue-select/dist/vue-select.css';

//Importo il logistix.config.js
var config = require('../../../logistix.config');

export default {
  components: {
    'v-select': VueSelect,
    'v-select-2': VueSelect
  },
  data () {
    return {
      options: [],
      selectedClient: null,
      searchClient: null,
      selectedDocument: null,
      confirmButton: false,
      loading: false
    }
  },
  methods: {
    onSearchClients(search, loading) {
      loading(true);
      this.searchClients(loading, search, this);
    },
    searchClients: function(loading, search, vm) {
      fetch(
          config.apiUrl.proxy + `rubrica_clienti.php?q=${escape(search)}`
      ).then(res => {

        res.json().then(json => (vm.options = json));
        loading(false);
      });
    },
    onSearchDocuments(search, loading) {
      loading(true);
      this.searchDocuments(loading, search, this);
    },
    searchDocuments: function(loading, search, vm) {
      fetch(
          config.apiUrl.proxy + `tipi_documento.php?q=${escape(search)}`
      ).then(res => {

        res.json().then(json => (vm.options = json));
        loading(false);
      });
    },
    updateSelectedClient: function(value) {
      if(value) {
        this.selectedDocument = value.documento_default;
        this.selectedClient = value;
        if(!value.documento_default) {
          setTimeout(()=>document.querySelector("#documento input").focus(),100);
        }
        else {
          this.confirmButton = true;
        }
      }
      else {
        this.selectedDocument = null;
        this.selectedClient = null;
        this.confirmButton = false;
        setTimeout(()=>document.querySelector("#documento input").focus(),100);
      }
    },
    updateSelectedDocument: function(value) {
      console.log(value);
      this.confirmButton = true;
    },
    sendData: function() {
      this.loading = true;

      setTimeout(()=>{
        this.loading = false;
        this.$toast.open({
          title: '',
          message: 'Dati inviati',
          type: 'success',
          timeout: 100
        });
      },3000);
    }
  }
}
</script>